<template>
  <div class="content content--home">
    <h1 class="title title--big title--theme title--indent">Создание задачи</h1>
    <form @submit.prevent="onCheckForm">
      <app-grid items="1" col="1">
        <template #item-1>
          <app-grid>
            <template #item-1>
              <app-form-group label-for="name" label="Название" required>
                <app-input
                  v-model.trim="form.name"
                  id="name"
                  placeholder="Введите Название"
                  :error="$v.form.name.$error"
                  @change.native="$v.form.name.$touch()"
                />
                <template #error>
                  <div v-if="$v.form.name.$dirty && !$v.form.name.required">Обязательное поле</div>
                </template>
              </app-form-group>
              <app-form-group label-for="desc" label="Описание" required>
                <vue-editor
                  v-model="form.desc"
                  :editor-toolbar="customToolbar"
                  ref="qEditor"
                  @paste.native="onEditorPast"
                  class="editor" />
                <template #error>
                  <div v-if="$v.form.desc.$dirty && !$v.form.desc.required">Обязательное поле</div>
                </template>
              </app-form-group>
              <app-form-group label-for="executor" label="Исполнитель" required>
                <app-select
                  v-model="form.executor"
                  :options="user_options"
                  :reduce="user => user.id"

                  :filterable="true"
                  :searchable="true"
                  :clearable="true"
                  multiple
                  label="id"
                  placeholder="Выберите участника"
                  class="select select--multiple"

                >
                  <template #open-indicator>
                    <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                      <path
                        d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                        fill="#fff"></path>
                    </svg>
                  </template>
                  <template #no-options>Ничего не найдено</template>
                  <template #option="option">
                    <div class="select__item d-center">{{ option.full_name }}</div>
                  </template>
                  <template #selected-option="option">
                    <div class="selected d-center">{{ option.full_name }}</div>
                  </template>
                </app-select>
                <template #error>
                  <div v-if="$v.form.executor.$dirty && !$v.form.executor.required">Обязательное поле</div>
                </template>
              </app-form-group>
              <app-form-group label-for="co_executor" label="Соисполнитель">
                <app-select
                  v-model="form.co_executor"
                  :options="user_options"
                  :reduce="user => user.id"
                  :filterable="true"
                  :searchable="true"
                  :clearable="true"
                  multiple
                  label="id"
                  placeholder="Выберите участника"
                  class="select select--multiple"
                >
                  <template #open-indicator>
                    <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                      <path
                        d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                        fill="#fff"></path>
                    </svg>
                  </template>
                  <template #no-options>Ничего не найдено</template>
                  <template #option="option">
                    <div class="select__item d-center">{{ option.full_name }}</div>
                  </template>
                  <template #selected-option="option">
                    <div class="selected d-center">{{ option.full_name }}</div>
                  </template>
                </app-select>
              </app-form-group>
              <app-form-group label-for="observer" label="Наблюдатель">
                <app-select
                  v-model="form.observer"
                  :options="user_options"
                  :reduce="user => user.id"
                  :filterable="true"
                  :searchable="true"
                  :clearable="true"
                  multiple
                  label="id"
                  placeholder="Выберите участника"
                  class="select select--multiple"
                >
                  <template #open-indicator>
                    <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                      <path
                        d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                        fill="#fff"></path>
                    </svg>
                  </template>
                  <template #no-options>Ничего не найдено</template>
                  <template #option="option">
                    <div class="select__item d-center">{{ option.full_name }}</div>
                  </template>
                  <template #selected-option="option">
                    <div class="selected d-center">{{ option.full_name }}</div>
                  </template>
                </app-select>
              </app-form-group>
            </template>
            <template #item-2>
              <app-form-group label-for="start_date" label="Дата начала">
                <app-input
                  @paste.native.prevent
                  v-model="form.start_date"
                  v-mask="'99.99.9999 99:99'"
                  id="start_date"
                  autocomplete="off"
                />
              </app-form-group>
              <app-form-group label-for="deadline" label="Крайний срок" required>
                <app-input
                  @paste.native.prevent
                  @input.native="$v.form.deadline.$touch"
                  v-model="form.deadline"
                  v-mask="'99.99.9999 99:99'"
                  id="deadline"
                  autocomplete="off"
                  :error="$v.form.deadline.$error"
                />
                <template #error>
                  <div v-if="$v.form.deadline.$dirty && !$v.form.deadline.underscorePresent">Заполните поле полностью</div>
                  <div v-if="$v.form.deadline.$dirty && !$v.form.deadline.required">Обязательное поле</div>
                </template>
              </app-form-group>
              <app-form-group label="Файлы">
                <app-multi-uploader
                  v-model="docs"
                  :limit="10"
                />
              </app-form-group>
            </template>
          </app-grid>
        </template>
      </app-grid>
      <app-cells>
        <app-button :disabled="$v.form.$error" ref="submit">Создать задачу</app-button>
      </app-cells>
    </form>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { VueEditor } from 'vue2-editor'
const underscorePresent = value => {
  if (!value) return true
  else return !value.includes('_')
}

export default {
  name: 'CreateTask',
  components: { VueEditor },
  data() {
    return {
      form: {
        name: null,
        desc: null,
        start_date: null,
        deadline: null,
        executor: [],
        co_executor: [],
        docs: [],
        observer: [],
      },
      docs: [],
      user_options: [],
      customToolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['link'], ['clean']
      ]

    }
  },
  validations: {
    form: {
      name: { required },
      desc: { required },
      deadline: { required, underscorePresent },
      executor: { required },
    },
  },
  created() {
    this.getUsers()
  },
  methods: {
    getUsers() {
      this.$store.dispatch('users/GET_DATA')
        .then(response => {
          this.user_options = response.data
        })
    },
    onCheckForm() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Проверьте правильность заполнения полей формы.'
        })
      } else {
        this.docs.map(item => this.form.docs.push(item.id))
        this.sendForm()
      }
    },
    sendForm() {
      this.$refs.submit.preload = true
      this.$store.dispatch('profile/task/POST_DATA', this.normalizeForm())
        .then(() => {
          this.$refs.submit.preload = false
          this.$notify({
            type: 'success',
            title: 'Успех!',
            text: 'Пользователь добавлен'
          })
          this.$router.push({ name: 'tasks-all' })
        })
        .catch(error => {
          this.$refs.submit.preload = false
          for (const key in error.response.data) {
            if (typeof error.response.data[key] === 'string') {
              this.$notify({
                type: 'error',
                title: 'Внимание!',
                text: error.response.data[key]
              })
            } else {
              this.$notify({
                type: 'error',
                title: 'Внимание!',
                text: error.response.data[key][0]
              })
            }
          }
        })
    },
    normalizeForm() {
      const { ...data } = this.form
      return data
    },
    onEditorPast() {
      this.$refs.qEditor.quill.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
        delta.ops = delta.ops.map(op => {
          return {
            insert: op.insert
          }
        })
        return delta
      })
    },
  }
}
</script>
